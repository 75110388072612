import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IOSSwitch } from '../../Common/IOSSwitch';
import { LayoutConfigContext } from '../../../contexts/LayoutConfigContext';

export const SwitchLanguage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const handlerLanguage = useContext(LayoutConfigContext).handlerLanguage;
    const language = useContext(LayoutConfigContext).language;
    const [firstRender, setFirstRender] = useState<boolean>(false);

    useEffect(() => {
        if (!firstRender) {
            return setFirstRender(true);
        }
        i18n.changeLanguage(language);
    }, [language]);

    return (
        <>
            <Typography>{language.toUpperCase()}</Typography>
            <IOSSwitch
                sx={{ m: 1 }}
                defaultChecked
                onChange={() => handlerLanguage()}
            />
        </>
    );
};
