import React, { useState, useEffect, useContext } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import type { EditorState } from 'lexical';
import axios from 'axios';

import {
    IPost,
    ICreateOrEditPost,
    IWebArchitecturePosts,
} from '../interfaces/post';
import { ICategory } from '../interfaces/categories';
import { AuthContext } from './AuthContext';
import { CatchErrors } from '../utils/CatchErrors';

type TPostContext = {
    toastrNotify: (message: string, type: TypeOptions) => void;
    handlerGetPosts: () => Promise<void>;
    handlerUpdatePost: (post: ICreateOrEditPost) => Promise<boolean>;
    handlePreEditPost: (post: IPost) => Promise<void>;
    handlerDeletePost: (post: IPost) => Promise<void>;
    handlerCreatePost: (post: ICreateOrEditPost) => Promise<boolean>;
    handlerSearchPostById: (searchField: string) => void;
    handlerSearchPostByAnyField: (searchField: string) => void;
    editPost: IPost;
    postsCount: number;
    activePostsCount: number;
    post: ICreateOrEditPost;
    posts: Array<IPost>;
    activePosts: Array<IPost>;
    webArchitecturePosts: IWebArchitecturePosts;
    handlerGetPostById: (id: string) => Promise<IPost>;
    editorES: EditorState;
    editorEN: EditorState;
    handleEditorStateES: (editor: EditorState) => void;
    handleEditorStateEN: (editor: EditorState) => void;
    handleCurrentAdminPostTab: (number: number) => void;
    handleCurrentWebArchitecturePageTab: (number: number) => void;
    handlerActivePost: (active: boolean, post: IPost) => void;
    currentAdminPostTab: number;
    currentWebArchitecturePageTab: number;
};

type TProps = {
    children: React.ReactNode;
};

export const PostContext = React.createContext<TPostContext>({
    posts: [{} as IPost],
    activePosts: [{} as IPost],
    webArchitecturePosts: {
        frontEnd: {
            languages: {
                en: {},
                es: {},
            },
        } as IPost,
        backEnd: {
            languages: {
                en: {},
                es: {},
            },
        } as IPost,
        architecture: {
            languages: {
                en: {},
                es: {},
            },
        } as IPost,
    },
    handlerGetPosts: async () => {},
    toastrNotify: (message: string, type: TypeOptions) => {},
    handlerUpdatePost: async (post: ICreateOrEditPost) => true || false,
    handlePreEditPost: async (post: IPost) => {},
    handlerActivePost: (active: boolean, post: IPost) => {},
    handlerDeletePost: async (post: IPost) => {},
    handlerSearchPostById: (searchField: string) => {},
    handlerSearchPostByAnyField: (searchField: string) => {},
    handlerGetPostById: async (id: string) => {
        return {} as IPost;
    },
    handlerCreatePost: async () => true || false,
    editPost: {
        languages: {
            en: {
                title: '',
                resume: '',
                html: '',
            },
            es: {
                title: '',
                resume: '',
                html: '',
            },
        },
        coverImageUrl: '',
        coverImageKey: '',
        active: false,
        categories: [{} as ICategory],
    },
    post: {
        languages: {
            en: {
                title: '',
                resume: '',
                html: '',
            },
            es: {
                title: '',
                resume: '',
                html: '',
            },
        },
        coverImage: {} as File,
        active: false,
        categories: [],
    },
    postsCount: 0,
    activePostsCount: 0,
    editorES: null as unknown as EditorState,
    editorEN: null as unknown as EditorState,
    handleEditorStateES: async () => {},
    handleEditorStateEN: async () => {},
    handleCurrentAdminPostTab: async () => {},
    handleCurrentWebArchitecturePageTab: async () => {},
    currentAdminPostTab: 0,
    currentWebArchitecturePageTab: 0,
});

export const PostContextProvider: React.FC<TProps> = (props) => {
    const token = useContext(AuthContext).token;
    const [currentAdminPostTab, setCurrentAdminPostTab] = useState<number>(0);
    const [posts, setPosts] = useState<Array<IPost>>([]);
    const [activePosts, setActivePosts] = useState<Array<IPost>>([]);
    const [webArchitecturePosts, setWebArchitecturePosts] =
        useState<IWebArchitecturePosts>({
            frontEnd: {
                languages: {
                    en: {},
                    es: {},
                },
            } as IPost,
            backEnd: {
                languages: {
                    en: {},
                    es: {},
                },
            } as IPost,
            architecture: {
                languages: {
                    en: {},
                    es: {},
                },
            } as IPost,
        } as IWebArchitecturePosts);
    const [pagePosts, setPagePosts] = useState<number>(1);
    const [postsCount, setPostsCount] = useState<number>(1);
    const [activePostsCount, setActivePostsCount] = useState<number>(1);
    const [currentWebArchitecturePageTab, setCurrentWebArchitecturePageTab] =
        useState<number>(0);
    const [post, setPost] = useState<ICreateOrEditPost>({
        languages: {
            en: {
                title: '',
                resume: '',
                html: '',
            },
            es: {
                title: '',
                resume: '',
                html: '',
            },
        },
        coverImage: {} as File,
        active: false,
        categories: [],
    });
    const [editPost, setEditPost] = useState<IPost>({} as IPost);
    const [editorES, setEditorES] = useState<EditorState>(
        null as unknown as EditorState
    );
    const [editorEN, setEditorEN] = useState<EditorState>(
        null as unknown as EditorState
    );

    useEffect(() => {
        try {
            handlerGetPosts();
        } catch (error) {
            console.error('Error PostContextProvider useEffect => ', error);
        }
    }, []);

    const handlerGetPostById = async (id: string) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}posts/find/${id}`
            );

            return data.post;
        } catch (error) {
            console.error('Error handlerGetPostById() => ', error);
        }
    };

    const handlerGetPosts = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}posts/${pagePosts}`
            );

            setPosts([...posts, ...data.posts]);
            setActivePosts([...activePosts, ...data.activePosts]);
            setWebArchitecturePosts(data.webArchitecturePosts);
            setPostsCount(data.postsCount);
            setActivePostsCount(data.activePostsCount);
            setPagePosts(pagePosts + 1);
        } catch (error) {
            CatchErrors(error, 'getPosts');
        }
    };

    const toastrNotify = (message: string, type: TypeOptions) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    const handleCurrentAdminPostTab = async (number: number) => {
        try {
            setCurrentAdminPostTab(number);
        } catch (error) {
            console.error('Error handleCurrentAdminPostTab () => ', error);
        }
    };

    const handleCurrentWebArchitecturePageTab = async (number: number) => {
        try {
            setCurrentWebArchitecturePageTab(number);
        } catch (error) {
            console.error(
                'Error handleCurrentWebArchitecturePageTab () => ',
                error
            );
        }
    };

    const handleEditorStateEN = async (editor: EditorState) => {
        try {
            setEditorEN(editor);
        } catch (error) {
            console.error('Error handleEditorState () => ', error);
        }
    };

    const handleEditorStateES = async (editor: EditorState) => {
        try {
            setEditorES(editor);
        } catch (error) {
            console.error('Error handleEditorState () => ', error);
        }
    };

    const handlerCreatePost = async (createPost: ICreateOrEditPost) => {
        try {
            toastrNotify('Creating a post...', 'warning');

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}pre-signed-url`,
                {
                    filename: createPost.coverImage!.name,
                    contentType: createPost.coverImage!.type,
                    s3BucketPrefix:
                        process.env
                            .REACT_APP_S3_BUCKET_PREFIX_FOR_POSTS_COVER_IMAGE,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}posts`,
                {
                    coverImageUrl: response.data.imageUrl,
                    coverImageKey: response.data.imageKey,
                    active: createPost.active,
                    tags: createPost.tags,
                    categories: createPost.categories,
                    languages: createPost.languages,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            await axios.put(response.data.url, createPost.coverImage, {
                headers: {
                    'Content-Type': createPost.coverImage!.type,
                },
                onUploadProgress: (progressEvent) => {
                    // Handle progress if needed
                    const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );

                    if (progress === 100) {
                        toastrNotify(
                            'Uploading image to s3 successfully',
                            'success'
                        );
                    }
                    console.log(`Upload Progress: ${progress}%`);
                },
            });

            setPosts([data.post, ...posts]);

            toastrNotify(data.message, 'success');

            return true;
        } catch (error) {
            CatchErrors(error, 'handlerCreatePost');
            return false;
        }
    };

    const handlePreEditPost = async (post: IPost) => {
        try {
            setEditPost(post);
        } catch (error) {
            console.error('Error handlePreEditPost () => ', error);
        }
    };

    const handlerUpdatePost = async (updatedPost: ICreateOrEditPost) => {
        try {
            toastrNotify('Updating a post...', 'warning');
            let response;
            if (updatedPost.coverImage) {
                response = await axios.post(
                    `${process.env.REACT_APP_API_URL}pre-signed-url`,
                    {
                        filename: updatedPost.coverImage!.name,
                        contentType: updatedPost.coverImage!.type,
                        s3BucketPrefix:
                            process.env
                                .REACT_APP_S3_BUCKET_PREFIX_FOR_POSTS_COVER_IMAGE,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            }

            const { data } = await axios.put(
                `${process.env.REACT_APP_API_URL}posts`,
                {
                    _id: updatedPost._id,
                    coverImageUrl: response
                        ? response.data.imageUrl
                        : undefined,
                    coverImageKey: response
                        ? response.data.imageKey
                        : undefined,
                    oldCoverImageKey: response
                        ? updatedPost.coverImageKey
                        : undefined,
                    active: updatedPost.active,
                    tags: updatedPost.tags,
                    categories: updatedPost.categories,
                    languages: updatedPost.languages,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response) {
                await axios.put(response.data.url, updatedPost.coverImage, {
                    headers: {
                        'Content-Type': updatedPost.coverImage!.type,
                    },
                    onUploadProgress: (progressEvent) => {
                        // Handle progress if needed
                        const progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total!
                        );

                        if (progress === 100) {
                            toastrNotify(
                                'Uploading image to s3 successfully',
                                'success'
                            );
                        }
                        console.log(`Upload Progress: ${progress}%`);
                    },
                });
            }

            const postIndexToUpdate = posts.findIndex(
                (post) => post._id === data.post._id
            );

            if (postIndexToUpdate !== -1) {
                const updatedPosts = [...posts];
                updatedPosts[postIndexToUpdate] = data.post;
                setPosts(updatedPosts);
            }

            const activePostIndexToUpdate = activePosts.findIndex(
                (post) => post._id === data.post._id
            );

            if (activePostIndexToUpdate !== -1) {
                const updatedActivePosts = [...activePosts];
                updatedActivePosts[activePostIndexToUpdate] = data.post;
                setActivePosts(updatedActivePosts);
            }

            // Find inside webArchitecturePosts if the post editing exist, if it is, update.
            const categoryKeys = Object.keys(webArchitecturePosts);
            const webArchitecturePostsIndexToUpdate = categoryKeys.findIndex(
                (categoryKey) =>
                    webArchitecturePosts[categoryKey]._id === data.post._id
            );

            if (webArchitecturePostsIndexToUpdate !== -1) {
                const updatedWebArchitecturePosts = { ...webArchitecturePosts };
                const categoryKeyToUpdate =
                    categoryKeys[webArchitecturePostsIndexToUpdate];
                updatedWebArchitecturePosts[categoryKeyToUpdate] = data.post;
                setWebArchitecturePosts(updatedWebArchitecturePosts);
            }

            toastrNotify(data.message, 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerUpdatePost');
            return false;
        }
    };

    const handlerDeletePost = async (post: IPost) => {
        try {
            toastrNotify('Deleting a post...', 'warning');
            const postIdToRemove = post._id;
            const { data } = await axios.delete(
                `${process.env.REACT_APP_API_URL}posts/delete/${post._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const filteredPosts = posts.filter(
                (post) => post._id !== postIdToRemove
            );
            setPosts(filteredPosts);
            toastrNotify(data.message, 'success');
        } catch (error) {
            CatchErrors(error, 'handlerDeletePost');
        }
    };

    const handlerActivePost = async (active: boolean, post: IPost) => {
        try {
            post.active = active;

            toastrNotify(`${active ? 'On' : 'Off'} post`, 'warning');

            const { data } = await axios.put(
                `${process.env.REACT_APP_API_URL}posts/active-or-unactive`,
                {
                    _id: post._id,
                    active: post.active,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const postIndexToUpdate = posts.findIndex(
                (post) => post._id === data.post._id
            );

            if (postIndexToUpdate !== -1) {
                const updatedPosts = [...posts];
                updatedPosts[postIndexToUpdate] = data.post;
                setPosts(updatedPosts);
            }

            if (data.post.active) {
                setActivePosts([data.post, ...activePosts]);
            } else {
                const activePostIndexToUpdate = activePosts.findIndex(
                    (post) => post._id === data.post._id
                );

                if (activePostIndexToUpdate !== -1) {
                    const updatedPosts = [...activePosts];
                    delete updatedPosts[activePostIndexToUpdate];
                    setActivePosts(updatedPosts);
                }
            }

            // Find inside webArchitecturePosts if the post editing exist, if it is, update.
            const categoryKeys = Object.keys(webArchitecturePosts);
            const webArchitecturePostsIndexToUpdate = categoryKeys.findIndex(
                (categoryKey) =>
                    webArchitecturePosts[categoryKey]._id === data.post._id
            );

            if (webArchitecturePostsIndexToUpdate !== -1) {
                const updatedWebArchitecturePosts = { ...webArchitecturePosts };
                const categoryKeyToUpdate =
                    categoryKeys[webArchitecturePostsIndexToUpdate];
                updatedWebArchitecturePosts[categoryKeyToUpdate] = data.post;
                setWebArchitecturePosts(updatedWebArchitecturePosts);
            }

            toastrNotify(
                `Succesfully ${active ? 'On' : 'Off'} post`,
                'success'
            );
        } catch (error: unknown) {
            CatchErrors(error, 'handlerActivePost');
        }
    };

    const handlerSearchPostById = (searchField: string) => {
        try {
            toastrNotify(`Finding a Post By ID ${searchField}`, 'success');
        } catch (error) {
            console.error('Error handlerSearchPost () => ', error);
        }
    };

    const handlerSearchPostByAnyField = (searchField: string) => {
        try {
            toastrNotify(`Finding a Post ${searchField}`, 'success');
        } catch (error) {
            console.error('Error handlerSearchPost () => ', error);
        }
    };

    const contextValue: TPostContext = {
        activePosts,
        activePostsCount,
        webArchitecturePosts,
        postsCount,
        handlerGetPosts,
        posts,
        toastrNotify,
        handlerCreatePost,
        editPost,
        handlerActivePost,
        handlerUpdatePost,
        handlePreEditPost,
        handlerDeletePost,
        handlerSearchPostById,
        handlerSearchPostByAnyField,
        handlerGetPostById,
        post,
        editorEN,
        editorES,
        handleEditorStateEN,
        handleEditorStateES,
        currentAdminPostTab,
        handleCurrentAdminPostTab,
        handleCurrentWebArchitecturePageTab,
        currentWebArchitecturePageTab,
    };

    return (
        <PostContext.Provider value={contextValue}>
            {props.children}
        </PostContext.Provider>
    );
};
