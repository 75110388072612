import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import './translations/i18n';
import { LayoutConfigContextProvider } from './contexts/LayoutConfigContext';
import { AuthContextProvider } from './contexts/AuthContext';
import { PostContextProvider } from './contexts/PostContext';
import { ImageContextProvider } from './contexts/ImageContext';
import { UserContextProvider } from './contexts/UserContext';
import { CategoryContextProvider } from './contexts/CategoriesContext';
import { TagContextProvider } from './contexts/TagsContext';

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <LayoutConfigContextProvider>
            <AuthContextProvider>
                <UserContextProvider>
                    <CategoryContextProvider>
                        <TagContextProvider>
                            <PostContextProvider>
                                <ImageContextProvider>
                                    <App />
                                </ImageContextProvider>
                            </PostContextProvider>
                        </TagContextProvider>
                    </CategoryContextProvider>
                </UserContextProvider>
            </AuthContextProvider>
        </LayoutConfigContextProvider>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
