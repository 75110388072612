import { lazy, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';

import Layout from '../pages/Layout';
import { AuthContext } from '../contexts/AuthContext';

const Home = lazy(() => import('../pages/Home'));
const WebArchitecture = lazy(() => import('../pages/WebArchitecture'));
const Post = lazy(() => import('../pages/Post'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));

const AdminUsers = lazy(() => import('../pages/Admin/AdminUsers'));
const AdminCategories = lazy(() => import('../pages/Admin/AdminCategories'));
const AdminTags = lazy(() => import('../pages/Admin/AdminTags'));
const AdminPosts = lazy(() => import('../pages/Admin/AdminPosts'));
const AdminWebArchitecture = lazy(
    () => import('../pages/Admin/AdminWebArchitecture')
);

const AdminImageManager = lazy(
    () => import('../pages/Admin/AdminImageManager')
);

export const CustomRoutes = () => {
    const token = useContext(AuthContext).token;

    const adminRoutes = (
        <>
            <Route
                path="admin/website-architecture"
                element={<AdminWebArchitecture />}
            />
            <Route path="admin/image-manager" element={<AdminImageManager />} />
            <Route path="admin/posts" element={<AdminPosts />} />
            <Route path="admin/users" element={<AdminUsers />} />
            <Route path="admin/categories" element={<AdminCategories />} />
            <Route path="admin/tags" element={<AdminTags />} />
        </>
    );

    const routes = (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route
                    path="website-architecture"
                    element={<WebArchitecture />}
                />
                <Route path="post/:postId" element={<Post />} />

                {token ? adminRoutes : undefined}
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
    return <>{routes}</>;
};
