import React, { useState, useContext, memo } from 'react';
import { Box, Typography, Grow, TextField, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LayoutConfigContext } from '../../contexts/LayoutConfigContext';
import { AuthContext } from '../../contexts/AuthContext';

type TProps = {
    handleModal: () => void;
    handleUserIconClose?: () => void;
};

export const LoginModal: React.FC<TProps> = memo((props) => {
    const [t, i18n] = useTranslation('auth');
    const [auth, setAuth] = useState({
        username: '',
        password: '',
    });

    const handleShowNavbarAdminLinks =
        useContext(LayoutConfigContext).handleShowNavbarAdminLinks;
    const toastrNotify = useContext(LayoutConfigContext).toastrNotify;
    const handlerAuth = useContext(AuthContext).handlerAuth;

    const handlerAuthOnChange = (event: any) => {
        const { name, value } = event.target;
        setAuth({ ...auth, [name]: value });
    };

    const submit = async () => {
        try {
            await handlerAuth({ auth, path: '/admin/posts' });
            setAuth({ username: '', password: '' });
            handleShowNavbarAdminLinks(true);
            props.handleModal();
            // retrieveTokenAndUserId(data.token, data.userId);
            if (typeof props.handleUserIconClose !== 'undefined') {
                props.handleUserIconClose();
            }
        } catch (error) {
            toastrNotify('No Data for Submit', 'warning');
            console.error('Error submit() => ', error);
        }
    };

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '40vh',
                    bgcolor: 'background.paper',
                    border: `2px solid ${process.env.REACT_APP_COLOR_MEDIUM_GRAY}`,
                    borderRadius: '4px',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Grow in={true}>
                    <Box>
                        <Stack direction="column" spacing={2}>
                            <Typography
                                align="center"
                                variant="h5"
                                color="text.primary"
                            >
                                {t('login')}
                            </Typography>
                            <TextField
                                id="standard-basic"
                                label={t('loginModal.username').toUpperCase()}
                                name="username"
                                value={auth.username}
                                onChange={handlerAuthOnChange}
                                sx={{
                                    color: 'text.primary',
                                    '& label.Mui-focused': {
                                        color: 'text.primary',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'text.primary',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                id="standard-password-input"
                                label={t('loginModal.password').toUpperCase()}
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                value={auth.password}
                                onChange={handlerAuthOnChange}
                                sx={{
                                    color: 'text.primary',
                                    '& label.Mui-focused': {
                                        color: 'text.primary',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'text.primary',
                                        },
                                    },
                                }}
                            />
                            <Button
                                // className={classes.Button}
                                variant="contained"
                                sx={{
                                    backgroundColor: 'background.withoutBlack',
                                    width: 'auto',
                                    border: `1px ${process.env.REACT_APP_COLOR_MEDIUM_GRAY} solid`,
                                }}
                                onClick={() => submit()}
                            >
                                {t('login')}
                            </Button>
                        </Stack>
                    </Box>
                </Grow>
            </Box>
        </>
    );
});
