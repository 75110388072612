import React from 'react';
import { useMediaQuery } from 'react-responsive';

type TProps = {
    children: React.ReactNode;
};

const useDesktopMediaQuery = () =>
    useMediaQuery({ query: '(min-width: 1001px)' });

const useTabletAndBelowMediaQuery = () =>
    useMediaQuery({ query: '(max-width: 1000px)' });

export const Desktop: React.FC<TProps> = ({ children }): JSX.Element => {
    const isDesktop = useDesktopMediaQuery();

    return <>{isDesktop ? children : null}</>;
};

export const TabletAndBelow: React.FC<TProps> = ({ children }): JSX.Element => {
    const isTabletAndBelow = useTabletAndBelowMediaQuery();

    return <>{isTabletAndBelow ? children : null}</>;
};
