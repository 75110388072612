import React, { useState, useContext, useCallback } from 'react';
import CircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../contexts/AuthContext';
import { LayoutConfigContext } from '../../contexts/LayoutConfigContext';
import { Modal } from '../Layout/Modal';
import { LoginModal } from '../Auth/LoginModal';

export const AccountCircleIcon: React.FC = () => {
    const [t, i18n] = useTranslation('auth');
    const token = useContext(AuthContext).token;
    const handlerLogout = useContext(AuthContext).handlerLogout;
    const showNavbarAdminLinks =
        useContext(LayoutConfigContext).showNavbarAdminLinks;
    const [userIcon, setUserIcon] = useState(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleOpenModal = useCallback(
        () => setOpenModal((prevState) => !prevState),
        []
    );

    const handleUserIcon = (event: any) => {
        setUserIcon(event.currentTarget);
    };

    const handleUserIconClose = useCallback(() => {
        setUserIcon(null);
    }, []);

    return (
        <>
            <CircleIcon
                onClick={handleUserIcon}
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                    },
                    fontSize: '40px',
                }}
            ></CircleIcon>
            <Menu
                id="simple-menu"
                anchorEl={userIcon}
                keepMounted
                open={Boolean(userIcon)}
                onClose={handleUserIconClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {token && (
                    <MenuItem
                        sx={{
                            textTransform: 'capitalize',
                        }}
                        onClick={() =>
                            showNavbarAdminLinks
                                ? navigate('/')
                                : navigate('/admin/posts')
                        }
                    >
                        {showNavbarAdminLinks ? t('home') : 'Admin'}
                    </MenuItem>
                )}
                <MenuItem
                    sx={{
                        textTransform: 'capitalize',
                    }}
                    onClick={() =>
                        token ? handlerLogout(false) : handleOpenModal()
                    }
                >
                    {token ? t('logout') : t('login')}
                </MenuItem>

                <Modal openModal={openModal} handleModal={handleOpenModal}>
                    <LoginModal
                        handleModal={handleOpenModal}
                        handleUserIconClose={handleUserIconClose}
                    />
                </Modal>
            </Menu>
        </>
    );
};
