import React from 'react';
import { Container, Box, Typography, AppBar } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Footer: React.FC = () => {
    const [t, i18n] = useTranslation('layout');
    return (
        <>
            <AppBar
                position="static"
                sx={{
                    px: { xs: 0, sm: 0 },
                    py: { xs: 5, sm: 5 },
                    backgroundColor: 'background.paper',
                    color: 'white',
                    textAlign: 'center',
                    position: 'fixed',
                    left: '0',
                    bottom: '0',
                    height: '5vh',
                    width: '100%',
                    '@media (min-width: 600px)': {
                        pb: '40px',
                        pt: '0px',
                    },
                    '@media (min-width: 0px)': {
                        pb: '40px',
                        pt: '0px',
                    },
                }}
            >
                <Container maxWidth="lg">
                    <Box textAlign="center">
                        <Typography color="text.primary" variant="h6">
                            Leonardo Aranguren S &reg;{' '}
                            {new Date().getFullYear()} | {t('footer.roles')}
                        </Typography>
                    </Box>
                </Container>
            </AppBar>
        </>
    );
};

// {t('footer.roles', { framework: 'React' })}
