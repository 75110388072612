import React, { useState, useEffect, useContext } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import axios from 'axios';

import { ITag } from '../interfaces/tags';
import { IChipSelector } from '../interfaces/common';
import { AuthContext } from './AuthContext';
import { CatchErrors } from '../utils/CatchErrors';

type TTagContext = {
    editTag: ITag;
    toastrNotify: (message: string, type: TypeOptions) => void;
    handlerPaginateTagsForAdmin: (page: number) => Promise<void>;
    handlerGetTags: () => Promise<void>;
    handlerCreateTag: (tag: ITag) => Promise<boolean | undefined>;
    handlerUpdateTag: (tag: ITag) => Promise<boolean | void>;
    handlerEditTag: (tag?: ITag) => boolean | undefined;
    handlerDeleteTag: (id: string | undefined) => Promise<boolean | undefined>;
    tags: Array<ITag>;
    tagsPerPage: Array<ITag>;
    tagsForChipSelector: IChipSelector;
    tagsCurrentPage: number;
    tagsTotalPages: number;
};

type TProps = {
    children: React.ReactNode;
};

export const TagContext = React.createContext<TTagContext>({
    toastrNotify: (message: string, type: TypeOptions) => {},
    editTag: {} as ITag,
    tags: [{} as ITag],
    tagsPerPage: [{} as ITag],
    tagsForChipSelector: { values: [], _ids: [] },
    handlerPaginateTagsForAdmin: async (page: number) => {},
    handlerGetTags: async () => {},
    handlerCreateTag: async (tag: ITag) => true || false,
    handlerDeleteTag: async (id: string | undefined) => true || false,
    handlerEditTag: (tag?: ITag) => true || false,
    handlerUpdateTag: async (tag: ITag) => {},
    tagsCurrentPage: 1,
    tagsTotalPages: 0,
});

export const TagContextProvider: React.FC<TProps> = (props) => {
    const token = useContext(AuthContext).token;
    const [tags, setTags] = useState<Array<ITag>>([]);
    const [tagsPerPage, setTagsPerPage] = useState<Array<ITag>>([]);
    const [tagsForChipSelector, setTagsForChipSelector] =
        useState<IChipSelector>({} as IChipSelector);
    const [tagsTotalPages, setTagsTotalPages] = useState<number>(0);
    const [tagsCurrentPage, setTagsCurrentPage] = useState<number>(1);
    const [tagsCount, setTagsCount] = useState<number>(1);
    const [firstRender, setFirstRender] = useState<boolean>(false);
    const [editTag, setEditTag] = useState<ITag>({ name: '' } as ITag);

    useEffect(() => {
        if (!firstRender) {
            return setFirstRender(true);
        }
        handlerPaginateTagsForAdmin(1);
    }, [tags]);

    const toastrNotify = (message: string, type: TypeOptions) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    const handlerEditTag = (tag?: ITag) => {
        try {
            if (tag) {
                setEditTag(tag);
            } else {
                setEditTag({
                    name: '',
                } as ITag);
            }
            return true;
        } catch (error) {
            console.error('Error handlerEditTag () => ', error);
        }
    };

    const handlerUpdateTag = async (tag: ITag) => {
        try {
            toastrNotify('Updating a tag...', 'warning');
            const { data } = await axios.put(
                `${process.env.REACT_APP_API_URL}tags`,
                {
                    _id: tag._id,
                    name: tag.name,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const tagIndexToUpdate = tags.findIndex(
                (tag) => tag._id === data.tag._id
            );

            const tagForChipSelectorIndexToUpdate =
                tagsForChipSelector._ids.findIndex(
                    (tag) => tag === data.tag._id
                );

            if (tagIndexToUpdate !== -1) {
                const updatedTags = [...tags];
                updatedTags[tagIndexToUpdate] = data.tag;
                setTags(updatedTags);

                const updatedIdsChipSelector = [...tagsForChipSelector._ids];
                const updatedValuesChipSelector = [
                    ...tagsForChipSelector.values,
                ];

                updatedIdsChipSelector[tagForChipSelectorIndexToUpdate] =
                    data.tag._id;
                updatedValuesChipSelector[tagForChipSelectorIndexToUpdate] =
                    data.tag.title;
                setTagsForChipSelector({
                    _ids: updatedIdsChipSelector,
                    values: updatedValuesChipSelector,
                });
            }

            setEditTag({
                name: '',
            } as ITag);

            toastrNotify(data.message, 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerUpdateTag');
        }
    };

    const handlerPaginateTagsForAdmin = async (page: number) => {
        try {
            const limit = parseInt(process.env.REACT_APP_LIMIT_TAGS!);
            setTagsTotalPages(Math.ceil(tagsCount / limit));
            const startIndex = (page - 1) * limit;
            const endIndex = page * limit;
            const paginatedItems = tags.slice(startIndex, endIndex);
            setTagsPerPage(paginatedItems);
            setTagsCurrentPage(page);
        } catch (error) {
            console.error('handlerPaginateTagsForAdmin() => ', error);
        }
    };

    const handlerGetTags = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}tags`
            );
            setTags([...tags, ...data.tags]);
            setTagsCount(data.count);

            // --------- Logic for chip Selector ------- //
            let chipSelector: IChipSelector = {
                _ids: [],
                values: [],
            };

            data.tags.forEach((tag: ITag) => {
                chipSelector._ids.push(tag._id!);
                chipSelector.values.push(tag.name);
            });

            setTagsForChipSelector(chipSelector);
            // --------- END Logic for chip Selector ------- //
        } catch (error) {
            CatchErrors(error, 'handlerGetTags');
        }
    };

    const handlerCreateTag = async (tag: ITag) => {
        try {
            toastrNotify('Creating a tag', 'warning');
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}tags`,
                { name: tag.name },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setTagsCount(data.count);
            setTags([data.tag, ...tags]);
            setTagsForChipSelector({
                _ids: [...tagsForChipSelector._ids, data.tag._id],
                values: [...tagsForChipSelector.values, data.tag.name],
            });
            toastrNotify(data.message, 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerCreateTag');
        }
    };

    const handlerDeleteTag = async (id: string | undefined) => {
        try {
            toastrNotify('Delating a tag...', 'warning');
            await axios.delete(
                `${process.env.REACT_APP_API_URL}tags/delete/${id}`
            );
            const filteredTags = tags.filter((tag) => tag._id !== id);
            setTags(filteredTags);
            toastrNotify('Succesfully deleted tag', 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerDeleteTag');
        }
    };

    const contextValue: TTagContext = {
        toastrNotify,
        editTag,
        tags,
        tagsForChipSelector,
        tagsPerPage,
        tagsTotalPages,
        tagsCurrentPage,
        handlerEditTag,
        handlerUpdateTag,
        handlerPaginateTagsForAdmin,
        handlerCreateTag,
        handlerDeleteTag,
        handlerGetTags,
    };

    return (
        <TagContext.Provider value={contextValue}>
            {props.children}
        </TagContext.Provider>
    );
};
