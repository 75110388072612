import React, { useState, useContext } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import axios from 'axios';

import { CatchErrors } from '../utils/CatchErrors';
import { AuthContext } from './AuthContext';
import { IImageManager } from '../interfaces/imageManager';

type TImageContext = {
    toastrNotify: (message: string, type: TypeOptions) => void;
    handlerUploadImage: (file: File, name: string) => Promise<boolean>;
    handlerGetImages: (page: number, isPaginatation?: boolean) => Promise<void>;
    handlerDeleteImage: (image: IImageManager) => Promise<void>;
    images: Array<IImageManager>;
    imagesTotalPages: number;
    imageCurrentPage: number;
};

type TProps = {
    children: React.ReactNode;
};

export const ImageContext = React.createContext<TImageContext>({
    toastrNotify: (message: string, type: TypeOptions) => {},
    handlerUploadImage: async (file: File, name: string) => true || false,
    handlerGetImages: async (page: number, isPaginatation?: boolean) => {},
    handlerDeleteImage: async (image: IImageManager) => {},
    images: [{} as IImageManager],
    imagesTotalPages: 0,
    imageCurrentPage: 1,
});

export const ImageContextProvider: React.FC<TProps> = (props) => {
    const token = useContext(AuthContext).token;
    const [images, setImages] = useState<Array<IImageManager>>([]);
    const [imagesCount, setImagesCount] = useState<number>(1);
    const [imagesTotalPages, setImagesTotalPages] = useState<number>(0);
    const [imageCurrentPage, setImageCurrentPage] = useState<number>(1);

    const toastrNotify = (message: string, type: TypeOptions) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    const handlerGetImages = async (page: number, isPaginatation?: boolean) => {
        try {
            if (isPaginatation) {
                toastrNotify('Fetching images...', 'warning');
            }

            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}image-manager/${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setImages(data.images);
            setImagesCount(data.count);
            setImagesTotalPages(data.totalPages);
            setImageCurrentPage(page);
            if (isPaginatation) {
                toastrNotify(data.message, 'success');
            }
        } catch (error) {
            CatchErrors(error, 'handlerGetImages');
        }
    };

    const handlerUploadImage = async (
        file: File,
        name: string
    ): Promise<boolean> => {
        try {
            toastrNotify('Creating image...', 'warning');

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}pre-signed-url`,
                {
                    filename: file.name,
                    contentType: file.type,
                    s3BucketPrefix:
                        process.env
                            .REACT_APP_S3_BUCKET_PREFIX_FOR_IMAGE_MANAGER,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}image-manager`,
                {
                    name,
                    imageUrl: response.data.imageUrl,
                    imageKey: response.data.imageKey,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            await axios.put(response.data.url, file, {
                headers: {
                    'Content-Type': file.type,
                },
                onUploadProgress: (progressEvent) => {
                    // Handle progress if needed
                    const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );

                    if (progress === 100) {
                        toastrNotify(
                            'Uploading image to s3 successfully',
                            'success'
                        );
                    }
                    console.log(`Upload Progress: ${progress}%`);
                },
            });

            setImages([data.image, ...images]);

            toastrNotify(data.message, 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerUploadImage');
            return false;
        }
    };

    const handlerDeleteImage = async (image: IImageManager) => {
        try {
            toastrNotify('Deleting image...', 'warning');
            const imageIdToRemove = image._id;
            const { data } = await axios.delete(
                `${process.env.REACT_APP_API_URL}image-manager/delete/${imageIdToRemove}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const filteredImages = images.filter(
                (image) => image._id !== imageIdToRemove
            );
            setImages(filteredImages);
            toastrNotify('Succesfully deleted image', 'success');
        } catch (error) {
            CatchErrors(error, 'handlerDeleteImage');
        }
    };

    const contextValue: TImageContext = {
        imageCurrentPage,
        handlerGetImages,
        imagesTotalPages,
        images,
        toastrNotify,
        handlerUploadImage,
        handlerDeleteImage,
    };

    return (
        <ImageContext.Provider value={contextValue}>
            {props.children}
        </ImageContext.Provider>
    );
};
