import React, { useState, useContext } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import axios from 'axios';

import { IUser } from '../interfaces/user';
import { CatchErrors } from '../utils/CatchErrors';
import { AuthContext } from './AuthContext';

type TUserContext = {
    editUser: IUser;
    users: Array<IUser>;
    toastrNotify: (message: string, type: TypeOptions) => void;
    handlerUploadImage: (file: File, path: string, name: string) => void;
    handlerCreateUser: (user: IUser) => Promise<boolean | undefined>;
    handlerUpdateUser: (user: IUser) => Promise<boolean | void>;
    handlerEditUser: (user?: IUser) => boolean | undefined;
    handlerGetUsers: () => Promise<void>;
    handlerDeleteUser: (id: string | undefined) => Promise<boolean | undefined>;
};

type TProps = {
    children: React.ReactNode;
};

export const UserContext = React.createContext<TUserContext>({
    editUser: {} as IUser,
    users: [],
    toastrNotify: (message: string, type: TypeOptions) => {},
    handlerUploadImage: (file: File, path: string, name: string) => {},
    handlerCreateUser: async (user: IUser) => true || false,
    handlerEditUser: (user?: IUser) => true || false,
    handlerGetUsers: async () => {},
    handlerUpdateUser: async (user: IUser) => {},
    handlerDeleteUser: async (id: string | undefined) => true || false,
});

export const UserContextProvider: React.FC<TProps> = (props) => {
    const token = useContext(AuthContext).token;
    const [users, setUsers] = useState<Array<IUser>>([]);
    const [editUser, setEditUser] = useState<IUser>({} as IUser);

    const toastrNotify = (message: string, type: TypeOptions) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    const handlerGetUsers = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}users`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setUsers([...users, ...data.users]);
        } catch (error) {
            CatchErrors(error, 'handlerGetUsers');
        }
    };

    const handlerUploadImage = (file: File, path: string, name: string) => {
        try {
            return toastrNotify('Succesfully upload image', 'success');
        } catch (error) {
            console.error('Error handlerUploadImage () => ', error);
        }
    };

    const handlerCreateUser = async (user: IUser) => {
        try {
            toastrNotify('Creating a user', 'warning');
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}users`,
                { user },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setUsers([data.user, ...users]);
            toastrNotify(data.message, 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerCreateUser');
        }
    };

    const handlerUpdateUser = async (user: IUser) => {
        try {
            toastrNotify('Updating a user...', 'warning');
            const { data } = await axios.put(
                `${process.env.REACT_APP_API_URL}users`,
                { user },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const userIndexToUpdate = users.findIndex(
                (user) => user._id === data.user._id
            );

            if (userIndexToUpdate !== -1) {
                const updatedUsers = [...users];
                updatedUsers[userIndexToUpdate] = data.user;
                setUsers(updatedUsers);
            }

            toastrNotify('Succesfully Updated User', 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerUpdateUser');
        }
    };
    const handlerDeleteUser = async (id: string | undefined) => {
        try {
            toastrNotify('Delating a user...', 'warning');
            await axios.delete(
                `${process.env.REACT_APP_API_URL}users/delete/${id}`
            );
            const filteredUsers = users.filter((user) => user._id !== id);
            setUsers(filteredUsers);
            toastrNotify('Succesfully deleted user', 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerDeleteUser');
        }
    };

    const handlerEditUser = (user?: IUser) => {
        try {
            if (user) {
                setEditUser(user);
            } else {
                setEditUser({} as IUser);
            }
            return true;
        } catch (error) {
            console.error('Error handlerEditUser () => ', error);
        }
    };

    const contextValue: TUserContext = {
        editUser,
        users,
        handlerGetUsers,
        toastrNotify,
        handlerUploadImage,
        handlerCreateUser,
        handlerEditUser,
        handlerUpdateUser,
        handlerDeleteUser,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {props.children}
        </UserContext.Provider>
    );
};
