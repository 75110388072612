import React, { memo } from 'react';
import { Modal as MUModal } from '@mui/material';

type TProps = {
    children: React.ReactNode;
    openModal: boolean;
    handleModal: () => void;
};

export const Modal: React.FC<TProps> = memo((props) => (
    <MUModal open={props.openModal} onClose={props.handleModal}>
        <>{props.children}</>
    </MUModal>
));
