import { Suspense, useContext } from 'react';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from '../components/Layout/Navigation/NavBar';
import { Footer } from '../components/Layout/Footer';
import { Spinner } from '../components/Layout/Spinner';
import { Desktop } from '../components/Layout/MediaQueries';
import { LayoutConfigContext } from '../contexts/LayoutConfigContext';

const Layout: React.FC = () => {
    const mode = useContext(LayoutConfigContext).mode;
    return (
        <div>
            <ToastContainer theme={mode} />
            <NavBar />
            <Suspense fallback={<Spinner />}>
                <Container
                    maxWidth="xl"
                    sx={{
                        mt: 1,
                        overflow: 'hidden',
                        '@media (min-width: 1001px)': {
                            // Desktop
                            maxHeight: '83vh',
                        },
                        '@media (max-width: 1000px)': {
                            // TabletAndBelow
                            maxHeight: '100vh',
                            p: '1px',
                        },
                    }}
                >
                    <Outlet />
                </Container>
            </Suspense>
            <Desktop>
                <Footer />
            </Desktop>
        </div>
    );
};

export default Layout;
