type TNavigationsList = {
    guess: Array<TList>;
    authenticatedUser: Array<TList>;
};

type TList = {
    to: string;
    name: string;
    external: boolean;
};

export const navigationLists = (t: any) => {
    let navigation: TNavigationsList;
    return (navigation = {
        guess: [
            { to: '/', name: t('navigationList.home'), external: false },
            {
                to: '/website-architecture',
                name: t('navigationList.websiteArchitecture'),
                external: false,
            },
            {
                to: 'https://www.credly.com/users/leonardo-aranguren-s/badges',
                name: t('navigationList.badges'),
                external: true,
            },
        ],
        authenticatedUser: [
            // { to: '/admin', name: 'Admin', external: false },
            {
                to: '/admin/posts',
                name: t('navigationList.posts'),
                external: false,
            },
            {
                to: '/admin/image-manager',
                name: t('navigationList.imageManager'),
                external: false,
            },
            {
                to: '/admin/users',
                name: t('navigationList.users'),
                external: false,
            },
            {
                to: '/admin/categories',
                name: t('navigationList.categories'),
                external: false,
            },
            {
                to: '/admin/tags',
                name: t('navigationList.tags'),
                external: false,
            },
            {
                to: '/admin/website-architecture',
                name: t('navigationList.websiteArchitecture'),
                external: false,
            },
        ],
    });
};
