import React, { useState, useContext, useEffect } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import axios from 'axios';
import { SelectChangeEvent } from '@mui/material';

import { ICategory } from '../interfaces/categories';
import {
    IChipSelectorByLanguages,
    ISelectorByLanguages,
} from '../interfaces/common';
import { AuthContext } from './AuthContext';
import { CatchErrors } from '../utils/CatchErrors';

type TCategoryContext = {
    editCategory: ICategory;
    categoryToFilter: string;
    toastrNotify: (message: string, type: TypeOptions) => void;
    handlerSelectorFilterCategory: (event: SelectChangeEvent) => void;
    handlerPaginateCategoriesForAdmin: (page: number) => Promise<void>;
    handlerGetCategories: () => Promise<void>;
    handlerUpdateCategory: (category: ICategory) => Promise<boolean | void>;
    handlerEditCategory: (category?: ICategory) => boolean | undefined;
    handlerCreateCategory: (
        category: ICategory
    ) => Promise<boolean | undefined>;
    handlerDeleteCategory: (
        id: string | undefined
    ) => Promise<boolean | undefined>;
    categories: Array<ICategory>;
    categoriesPerPage: Array<ICategory>;
    categoriesForChipSelector: IChipSelectorByLanguages;
    categoriesForSelector: ISelectorByLanguages;
    categoriesCurrentPage: number;
    categoriesTotalPages: number;
};

type TProps = {
    children: React.ReactNode;
};

export const CategoryContext = React.createContext<TCategoryContext>({
    toastrNotify: (message: string, type: TypeOptions) => {},
    editCategory: {} as ICategory,
    categoryToFilter: '',
    categories: [
        {
            title: '',
            languages: {
                en: {
                    name: '',
                },
                es: {
                    name: '',
                },
            },
        } as ICategory,
    ],
    categoriesPerPage: [{} as ICategory],
    categoriesForChipSelector: { values: [], _ids: [] },
    categoriesForSelector: { values: [], _ids: [] },
    handlerEditCategory: (category?: ICategory) => true || false,
    handlerSelectorFilterCategory: (event: SelectChangeEvent) => {},
    handlerUpdateCategory: async (category: ICategory) => {},
    handlerPaginateCategoriesForAdmin: async (page: number) => {},
    handlerGetCategories: async () => {},
    handlerCreateCategory: async (category: ICategory) => true || false,
    handlerDeleteCategory: async (id: string | undefined) => true || false,
    categoriesCurrentPage: 1,
    categoriesTotalPages: 0,
});

export const CategoryContextProvider: React.FC<TProps> = (props) => {
    const token = useContext(AuthContext).token;
    const [categoryToFilter, setCategoryToFilter] = useState<string>('');
    const [categories, setCategories] = useState<Array<ICategory>>([]);
    const [categoriesPerPage, setCategoriesPerPage] = useState<
        Array<ICategory>
    >([]);
    const [categoriesTotalPages, setCategoriesTotalPages] = useState<number>(0);
    const [editCategory, setEditCategory] = useState<ICategory>(
        {} as ICategory
    );
    const [categoriesCurrentPage, setCategoriesCurrentPage] =
        useState<number>(1);
    const [categoriesCount, setCategoriesCount] = useState<number>(1);
    const [categoriesForChipSelector, setCategoriesForChipSelector] =
        useState<IChipSelectorByLanguages>({} as IChipSelectorByLanguages);
    const [categoriesForSelector, setCategoriesForSelector] =
        useState<ISelectorByLanguages>({} as ISelectorByLanguages);
    const [firstRender, setFirstRender] = useState<boolean>(false);

    useEffect(() => {
        if (!firstRender) {
            return setFirstRender(true);
        }
        handlerPaginateCategoriesForAdmin(1);
    }, [categories]);

    const toastrNotify = (message: string, type: TypeOptions) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    const handlerPaginateCategoriesForAdmin = async (page: number) => {
        try {
            const limit = parseInt(process.env.REACT_APP_LIMIT_CATEGORIES!);
            setCategoriesTotalPages(Math.ceil(categoriesCount / limit));
            const startIndex = (page - 1) * limit;
            const endIndex = page * limit;
            const paginatedItems = categories.slice(startIndex, endIndex);
            setCategoriesPerPage(paginatedItems);
            setCategoriesCurrentPage(page);
        } catch (error) {
            console.error('handlerPaginateCategoriesForAdmin() => ', error);
        }
    };

    const handlerSelectorFilterCategory = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;

        setCategoryToFilter(value);
    };

    const handlerGetCategories = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}categories`
            );

            setCategories([...categories, ...data.categories]);
            setCategoriesCount(data.count);

            // --------- Logic for chip Selector ------- //
            let chipSelector: IChipSelectorByLanguages = {
                _ids: [],
                values: [],
            };

            let selector: ISelectorByLanguages = {
                _ids: ['All'],
                values: [
                    {
                        languages: {
                            en: { name: 'All' },
                            es: { name: 'Todos' },
                        },
                    },
                ],
            };

            data.categories.forEach((category: ICategory) => {
                chipSelector._ids.push(category._id!);
                selector._ids.push(category._id!);
                chipSelector.values.push({ languages: category.languages });
                selector.values.push({ languages: category.languages });
            });

            setCategoriesForChipSelector(chipSelector);
            setCategoriesForSelector(selector);

            // --------- END Logic for chip Selector ------- //
        } catch (error) {
            CatchErrors(error, 'handlerGetCategories');
        }
    };

    const handlerCreateCategory = async (category: ICategory) => {
        try {
            toastrNotify('Creating a category', 'warning');

            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}categories`,
                { title: category.title, languages: category.languages },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setCategoriesCount(data.count);
            setCategories([data.category, ...categories]);
            setCategoriesForChipSelector({
                _ids: [...categoriesForChipSelector._ids, data.category._id],
                values: [
                    ...categoriesForChipSelector.values,
                    data.category.languages,
                ],
            });

            setCategoriesForSelector({
                _ids: [...categoriesForSelector._ids, data.category._id],
                values: [
                    ...categoriesForSelector.values,
                    data.category.languages,
                ],
            });
            toastrNotify(data.message, 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerCreateCategory');
        }
    };

    const handlerDeleteCategory = async (id: string | undefined) => {
        try {
            toastrNotify('Delating a category...', 'warning');
            await axios.delete(
                `${process.env.REACT_APP_API_URL}categories/delete/${id}`
            );
            const filteredCategories = categories.filter(
                (category) => category._id !== id
            );
            setCategories(filteredCategories);
            toastrNotify('Succesfully deleted category', 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerDeleteCategory');
        }
    };

    const handlerEditCategory = (category?: ICategory) => {
        try {
            if (category) {
                setEditCategory(category);
            } else {
                setEditCategory({
                    title: '',
                    languages: {
                        en: {
                            name: '',
                        },
                        es: {
                            name: '',
                        },
                    },
                } as ICategory);
            }
            return true;
        } catch (error) {
            console.error('Error handlerEditCategory () => ', error);
        }
    };

    const handlerUpdateCategory = async (category: ICategory) => {
        try {
            toastrNotify('Updating a category...', 'warning');
            const { data } = await axios.put(
                `${process.env.REACT_APP_API_URL}categories`,
                {
                    _id: category._id,
                    title: category.title,
                    languages: category.languages,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const categoryIndexToUpdate = categories.findIndex(
                (category) => category._id === data.category._id
            );

            const categoryForChipSelectorIndexToUpdate =
                categoriesForChipSelector._ids.findIndex(
                    (category) => category === data.category._id
                );

            const categoryForSelectorIndexToUpdate =
                categoriesForSelector._ids.findIndex(
                    (category) => category === data.category._id
                );

            if (categoryIndexToUpdate !== -1) {
                const updatedCategories = [...categories];
                updatedCategories[categoryIndexToUpdate] = data.category;
                setCategories(updatedCategories);

                const updatedIdsChipSelector = [
                    ...categoriesForChipSelector._ids,
                ];
                const updatedValuesChipSelector = {
                    ...categoriesForChipSelector.values,
                };
                const updatedIdsSelector = [...categoriesForSelector._ids];
                const updatedValuesSelector = {
                    ...categoriesForSelector.values,
                };

                updatedIdsChipSelector[categoryForChipSelectorIndexToUpdate] =
                    data.category._id;
                updatedIdsSelector[categoryForSelectorIndexToUpdate] =
                    data.category._id;
                updatedValuesChipSelector[
                    categoryForChipSelectorIndexToUpdate
                ] = { languages: data.category.languages };
                updatedValuesSelector[categoryForSelectorIndexToUpdate] = {
                    languages: data.category.languages,
                };
                setCategoriesForChipSelector({
                    _ids: updatedIdsChipSelector,
                    values: updatedValuesChipSelector,
                });
                setCategoriesForSelector({
                    _ids: updatedIdsSelector,
                    values: updatedValuesSelector,
                });
            }

            setEditCategory({
                title: '',
                languages: {
                    en: {
                        name: '',
                    },
                    es: {
                        name: '',
                    },
                },
            } as ICategory);

            toastrNotify('Succesfully Updated Category', 'success');
            return true;
        } catch (error) {
            CatchErrors(error, 'handlerUpdateCategory');
        }
    };

    const contextValue: TCategoryContext = {
        toastrNotify,
        handlerSelectorFilterCategory,
        categoryToFilter,
        categoriesPerPage,
        categoriesTotalPages,
        categoriesCurrentPage,
        categories,
        categoriesForChipSelector,
        categoriesForSelector,
        editCategory,
        handlerEditCategory,
        handlerUpdateCategory,
        handlerPaginateCategoriesForAdmin,
        handlerCreateCategory,
        handlerDeleteCategory,
        handlerGetCategories,
    };

    return (
        <CategoryContext.Provider value={contextValue}>
            {props.children}
        </CategoryContext.Provider>
    );
};
