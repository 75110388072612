import React, { useEffect, useState } from 'react';
import { PaletteMode } from '@mui/material';
import { toast, TypeOptions } from 'react-toastify';

type TLanguage = 'en' | 'es';

type TLayoutConfigContext = {
    mode: PaletteMode;
    language: TLanguage;
    handlerLanguage: () => void;
    showNavbarAdminLinks: boolean;
    toggleColorMode: () => void;
    handleShowNavbarAdminLinks: (boolean: boolean) => void;
    toastrNotify: (message: string, type: TypeOptions) => void;
};

type TProps = {
    children: React.ReactNode;
};

export const LayoutConfigContext = React.createContext<TLayoutConfigContext>({
    mode: '' as PaletteMode,
    language: '' as TLanguage,
    handlerLanguage: () => {},
    toggleColorMode: () => {},
    showNavbarAdminLinks: false,
    handleShowNavbarAdminLinks: () => {},
    toastrNotify: (message: string, type: TypeOptions) => {},
});

export const LayoutConfigContextProvider: React.FC<TProps> = (props: any) => {
    const [mode, setMode] = useState<'light' | 'dark'>('dark');
    const [language, setLanguage] = useState<TLanguage>('en');
    const [showNavbarAdminLinks, setShowNavbarAdminLinks] =
        useState<boolean>(true);
    const [firstRender, setFirstRender] = useState<boolean>(false);

    const toastrNotify = (message: string, type: TypeOptions) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    const handleShowNavbarAdminLinks = (boolean: boolean) => {
        setShowNavbarAdminLinks(boolean);
    };

    const handlerLanguage = () => {
        try {
            setLanguage((prevState) => (prevState === 'en' ? 'es' : 'en'));
        } catch (error) {
            console.error('Error handlerLanguage () => ', error);
        }
    };

    // Store Mode in browser for navigate with the same Mode
    useEffect(() => {
        const storedMode = localStorage.getItem('mode');
        const storedLanguage = localStorage.getItem('language');
        if (storedMode === 'light' || storedMode === 'dark') {
            setMode(storedMode);
        }
        if (storedLanguage === 'en' || storedLanguage === 'es') {
            setLanguage(storedLanguage);
        }
    }, []);

    useEffect(() => {
        if (!firstRender) {
            return setFirstRender(true);
        }
        localStorage.setItem('mode', mode);
        localStorage.setItem('language', language);
    }, [mode, language]);

    // END - Store Mode in browser for navigate with the same Mode

    const toggleColorMode = () => {
        setMode((prevState) => (prevState === 'light' ? 'dark' : 'light'));
    };

    const contextValue: TLayoutConfigContext = {
        language,
        handlerLanguage,
        mode,
        toggleColorMode,
        toastrNotify,
        showNavbarAdminLinks,
        handleShowNavbarAdminLinks,
    };

    return (
        <LayoutConfigContext.Provider value={contextValue}>
            {props.children}
        </LayoutConfigContext.Provider>
    );
};
