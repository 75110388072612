import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, TypeOptions } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { CatchErrors } from '../utils/CatchErrors';

type TAuthContext = {
    token: string | null;
    handlerAuth: ({ auth, path }: THandlerAuth) => Promise<void>;
    handlerLogout: (usingUseEffect: boolean) => void;
};

type TProps = {
    children: React.ReactNode;
};

type THandlerAuth = {
    path: string;
    auth: {
        username: string;
        password: string;
    };
};

export const AuthContext = React.createContext<TAuthContext>({
    token: '',
    handlerAuth: async ({ auth, path }: THandlerAuth) => {},
    handlerLogout: (usingUseEffect: boolean) => {},
});

export const AuthContextProvider: React.FC<TProps> = (props) => {
    const [t, i18n] = useTranslation('auth');
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(
        localStorage.getItem('token')
    );
    const [expiryDate, setExpiryDate] = useState(
        localStorage.getItem('expiryDate')
    );

    useEffect(() => {
        try {
            // if (expiryDate) {
            //     if (new Date(expiryDate) <= new Date()) {
            //         handlerLogout(true);
            //     } else {
            //         setAutoLogout(parseInt(expiryDate));
            //     }
            // }
        } catch (error) {
            console.error('Error AuthContextProvider useEffect => ', error);
        }
    }, []);

    const toastrNotify = (message: string, type: TypeOptions) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    const handlerAuth = async ({ auth, path }: THandlerAuth) => {
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}signin`,
                {
                    username: auth.username,
                    password: auth.password,
                }
            );

            if (data.token) {
                setToken(data.token);
                localStorage.setItem('token', data.token);
                const expiryDate =
                    new Date().getTime() +
                    parseInt(process.env.REACT_APP_SESSION_MILLISECONDS!);
                localStorage.setItem('expiryDate', expiryDate.toString());
                setAutoLogout(expiryDate);
                toastrNotify(`${t('welcome')}!`, 'success');
                navigate(path);
            }
        } catch (error) {
            CatchErrors(error, 'handlerAuth');
        }
    };

    const handlerLogout = (usingUseEffect = false) => {
        try {
            // if (!usingUseEffect) {
            //     toastrNotify('Bye bye! See ya!', 'success');
            // }
            localStorage.removeItem('token');
            localStorage.removeItem('expiryDate');
            setToken('');
            setExpiryDate('');
            toastrNotify('Bye bye! See ya!', 'success');
            navigate('/');
        } catch (error) {
            console.error('logout() => ', error);
        }
    };

    const setAutoLogout = (milliseconds: number) => {
        const now = new Date().getTime();
        setTimeout(() => {
            handlerLogout(true);
        }, milliseconds - now);
    };

    const contextValue: TAuthContext = {
        handlerAuth,
        token,
        handlerLogout,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};
