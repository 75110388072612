import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IOSSwitch } from '../../Common/IOSSwitch';
import { LayoutConfigContext } from '../../../contexts/LayoutConfigContext';

export const SwitchThemeColorMode: React.FC = () => {
    const [t, i18n] = useTranslation('layout');
    const mode = useContext(LayoutConfigContext).mode;
    const handleThemeColorMode =
        useContext(LayoutConfigContext).toggleColorMode;

    return (
        <>
            <Typography>
                {mode === 'dark' ? t('theme.dark') : t('theme.light')}
            </Typography>
            <IOSSwitch
                sx={{ m: 1 }}
                defaultChecked
                onChange={handleThemeColorMode}
            />
        </>
    );
};
